import { Controller } from '@hotwired/stimulus';
import { Datepicker } from 'vanillajs-datepicker';

export default class extends Controller {
  static targets = ['dispatchDate', 'deliveryDate', 'eventDate', 'warehouseReturnDate'];
  static SUNDAY = 0;
  static SATURDAY = 6;

  connect() {
    this.loadDatePicker();
  }

  loadDatePicker() {
    const today = new Date();

    const datepickerTargets = [
      this.dispatchDateTarget,
      this.deliveryDateTarget,
      this.eventDateTarget,
      this.warehouseReturnDateTarget,
    ];

    datepickerTargets.forEach((target) => {
      new Datepicker(target, {
        buttonClass: 'btn',
        format: 'yyyy-mm-dd',
        startDate: target.value,
        minDate: today,
        beforeShowDay: (date) => {
          if (target !== this.dispatchDateTarget) {
            return {
              enabled: true,
            };
          }
          if (this.weekend(date)) {
            return {
              enabled: false,
            };
          }

          return {
            enabled: true,
          };
        },
      });
    });
  }

  weekend(date) {
    const day = date.getDay();

    return day === this.constructor.SUNDAY || day === this.constructor.SATURDAY;
  }
}
